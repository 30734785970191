/* eslint-disable max-len */
type TriangleProps = {
  color: string
  draw?: 'stroke' | 'fill'
  className?: string
  strokeWidth?: string
}

export const Triangle = ({ color, draw = 'fill', className, ...rest }: TriangleProps) => {
  let drawColor = color
  if (drawColor === 'blue') {
    drawColor = '#00BFFF'
  } else if (drawColor === 'pink') {
    drawColor = '#CC00A1'
  }

  return (
    <svg viewBox="0 0 256 291" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M239.42 118.054C260.055 130.195 260.055 160.563 239.42 172.704L47.7816 285.453C27.1835 297.572 1.37926 282.443 1.37926 258.128L1.37927 32.6304C1.37927 8.31545 27.1836 -6.81352 47.7816 5.30519L239.42 118.054Z"
        fill={draw === 'fill' ? drawColor : ''}
        stroke={draw === 'stroke' ? drawColor : ''}
        {...rest}
      />
    </svg>
  )
}
