import { memo, useCallback } from 'react'

import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import { CtaBar } from 'modules/cta-bar'
import { Layout } from 'modules/layout'
import { buildSimulatorUrl } from 'src/utils/buildSimulatorUrl'
import { pushDataLayer } from 'utils/push-data-layer'

import Hero from './hero'
import Products from './products'

const Benefits = dynamic(() => import('./benefits'))
const Blog = dynamic(() => import('modules/blog'))
const Feedbacks = dynamic(() => import('modules/feedbacks'))
const Help = dynamic(() => import('./help'))
const Solutions = dynamic(() => import('./solutions'))
const Statistics = dynamic(() => import('./statistics'))

export const Home = memo(() => {
  const router = useRouter()

  const handleClick = useCallback(
    (clickTarget: string, label: string) => {
      pushDataLayer({
        category: 'Clique',
        action: 'Botões',
        label: `${clickTarget} - Home - ${label}`
      })

      location.href = buildSimulatorUrl(router.pathname, { medium: clickTarget + '-' + label })
    },
    [router.pathname]
  )

  return (
    <Layout>
      <Hero onClick={() => handleClick('Banner', 'Simular agora')} />
      <Products />
      <Benefits />
      <Solutions />
      <Feedbacks />
      <Help />
      <Statistics />
      <Blog />
      <CtaBar
        title="Simule agora o seu empréstimo"
        buttonLabel="Simular agora"
        onClick={() => handleClick('Flutuante', 'Simular agora')}
      />
    </Layout>
  )
})

Home.displayName = 'Home'
