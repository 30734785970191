import { fontSizes } from '@cash-me/web-tokens'
import styled from 'styled-components'

import { Section } from 'components/general-styles'
import { theme } from 'styles/theme'

const { media, space, shadows } = theme

export const Products = styled.section`
  > div {
    @media not screen and (${media.hd}) {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

export const Content = styled(Section)`
  box-shadow: ${shadows['shadow-level-4']} rgba(0 0 0 / 24%);
  border-radius: ${space['spacing-md']} 0 0 0;
  padding-top: ${space['spacing-xxxl']};
  padding-left: ${space['spacing-md']};
  padding-bottom: ${space['spacing-xxxl']};

  > div:first-child {
    padding-right: ${space['spacing-md']};
  }

  @media (${media.tablet}) {
    border-radius: 0;
    box-shadow: none;
  }

  @media (${media.desktop}) {
    padding-top: 7rem;
    padding-left: 8.5rem;
    padding-bottom: 7rem;
  }
`

export const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: ${space['spacing-md']};

  @media (${media.tablet}) {
    flex-direction: row;
  }
`

export const TabInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space['spacing-sm']};
  white-space: normal;

  p {
    max-width: 20rem;
  }

  @media (${media.tablet}) {
    justify-content: center;
    width: 26rem;
    min-width: 20rem;
    gap: ${space['spacing-md']};
  }

  @media (${media.desktop}) {
    h2 {
      font-size: ${fontSizes['font-size-heading-2']};
      
      span {
        font-size: ${fontSizes['font-size-heading-2']};
      }
    }
  }
`
