import { memo } from 'react'

import { Text } from '@cash-me/react-components'

import { Grid, Col } from 'components/grid'
import { Tabs, Tab } from 'components/tabs'

import { listYou, listBusiness, listCondo, listConsorcio } from './data'
import { ProductList } from './product-list'
import * as S from './styles'

const Products = memo(() => (
  <S.Products>
    <Grid col={{ phone: 1, tablet: 1, desktop: 1 }}>
      <Col>
        <S.Content>
          <Tabs
            activeTabIndex={0}
            dataLayer={[
              {
                index: 0,
                category: 'Clique',
                action: 'Portfólio',
                label: 'Tab Button - Para você'
              },
              {
                index: 1,
                category: 'Clique',
                action: 'Portfólio',
                label: 'Tab Button - Para seu Negócio'
              },
              {
                index: 2,
                category: 'Clique',
                action: 'Portfólio',
                label: 'Tab Button - Para seu condomínio'
              },
              {
                index: 2,
                category: 'Clique',
                action: 'Portfólio',
                label: 'Tab Button - Consórcio'
              }
            ]}
          >
            <Tab name="Para você">
              <S.TabContentWrapper>
                <S.TabInfo>
                  <Text as="h2" size="heading3" weight="medium" color="var(--colors-neutral-text-default)">
                    Veja os empréstimos que temos{' '}
                    <Text as="span" size="heading3" weight="medium" color="var(--colors-pink-text-default)">
                      para você
                    </Text>
                  </Text>
                  <Text color="var(--colors-neutral-text-weak)">
                    Conheça os produtos que combinam com a sua necessidade.
                  </Text>
                </S.TabInfo>
                <ProductList products={listYou} />
              </S.TabContentWrapper>
            </Tab>
            <Tab name="Para seu negócio">
              <S.TabContentWrapper>
                <S.TabInfo>
                  <Text as="h2" size="heading3" weight="medium" color="var(--colors-neutral-text-default)">
                    Empréstimos que combinam com o seu{' '}
                    <Text as="span" size="heading3" weight="medium" color="var(--colors-pink-text-default)">
                      negócio
                    </Text>
                  </Text>
                  <Text color="var(--colors-neutral-text-weak)">
                    Tenha acesso ao crédito rápido e digital, exclusivo para empresas.
                  </Text>
                </S.TabInfo>
                <ProductList products={listBusiness} />
              </S.TabContentWrapper>
            </Tab>
            <Tab name="Condomínio">
              <S.TabContentWrapper>
                <S.TabInfo>
                  <Text as="h2" size="heading3" weight="medium" color="var(--colors-neutral-text-default)">
                    Realize projetos no seu{' '}
                    <Text as="span" size="heading3" weight="medium" color="var(--colors-pink-text-default)">
                      condomínio
                    </Text>{' '}
                    mesmo sem ter dinheiro em caixa
                  </Text>
                  <Text color="var(--colors-neutral-text-weak)">Transforme para melhor o que já é realidade.</Text>
                </S.TabInfo>
                <ProductList products={listCondo} />
              </S.TabContentWrapper>
            </Tab>
            <Tab name="Consórcio">
              <S.TabContentWrapper>
                <S.TabInfo>
                  <Text as="h2" size="heading3" weight="medium" color="var(--colors-neutral-text-default)">
                    Planeje suas conquistas com{' '}
                    <Text as="span" size="heading3" weight="medium" color="var(--colors-pink-text-default)">
                      segurança e economia
                    </Text>
                  </Text>
                  <Text color="var(--colors-neutral-text-weak)">
                    Opções com parcelas reduzidas até a contemplação. Sem juros e taxa de adesão.
                  </Text>
                </S.TabInfo>
                <ProductList products={listConsorcio} />
              </S.TabContentWrapper>
            </Tab>
          </Tabs>
        </S.Content>
      </Col>
    </Grid>
  </S.Products>
))

Products.displayName = 'Products'

export default Products
