import { FC, memo } from 'react'

import { Button, Text } from '@cash-me/react-components'
import NextImage from 'next/image'

import { Grid, Col } from 'components/grid'
import { Polygon } from 'components/polygon'
import useWindowSize from 'hooks/useWindowSize'

import { HeroProps } from './hero.model'
import * as S from './styles'

const Hero: FC<HeroProps> = memo(({ onClick }) => {
  const { isMobile } = useWindowSize()
  const heroImage = !isMobile ? 'hero-desktop.png?updated=05072022' : 'hero-mobile.png?updated=05072022'

  return (
    <>
      <S.Hero>
        <Grid col={{ phone: 1, tablet: 1, desktop: 1 }}>
          <Col>
            <div>
              <S.ImageWrapper>
                <NextImage
                  src={`${process.env.NEXT_PUBLIC_IMAGES_PREFIX}/home/${heroImage}`}
                  alt="Empréstimo seguro que impulsiona seus sonhos"
                  width={!isMobile ? '991' : '262'}
                  height={!isMobile ? '704' : '233'}
                  quality="100"
                  priority
                  sizes="100vw"
                  style={{
                    width: '100%',
                    height: 'auto'
                  }}
                />
              </S.ImageWrapper>

              <S.Polygons>
                <Polygon type="Triangle" width={163} height={145} draw="fill" color="blue" className="blue-polygon" />
                <Polygon
                  type="Triangle"
                  width={163}
                  height={145}
                  draw="stroke"
                  color="pink"
                  strokeWidth="1"
                  className="pink-polygon"
                />
              </S.Polygons>

              <S.TextWrapper>
                <Text as="h1" size="caption" weight="medium" uppercase>
                  Empréstimo
                </Text>

                <Text as="h2" size="heading3" weight="medium">
                  Crédito que {''}
                  <br />
                  <Text as="span" size="heading3" weight="medium" color="var(--colors-pink-text-default)">
                    impulsiona seus {''}
                    <br />
                    melhores planos
                  </Text>
                </Text>

                <Text color="var(--colors-neutral-text-weak)">
                  Use o seu imóvel e tenha acesso ao crédito mais rápido, com o nosso processo simplificado e 100%
                  online.
                </Text>

                <Button color="primary" size="md" onClick={onClick}>
                  Simule agora
                </Button>
              </S.TextWrapper>
            </div>
          </Col>
        </Grid>
      </S.Hero>

      <S.DesktopPolygons>
        <Polygon type="Triangle" width={163} height={145} draw="fill" color="blue" className="blue-polygon" />
        <Polygon
          type="Triangle"
          width={163}
          height={145}
          draw="stroke"
          color="pink"
          strokeWidth="1"
          className="pink-polygon"
        />
      </S.DesktopPolygons>
    </>
  )
})

Hero.displayName = 'Hero'

export default Hero
