import styled from 'styled-components'

import { theme } from 'styles/theme'

const { fontSizes, media, space, colors } = theme

export const ProductsWrapper = styled.div`
  display: inline-flex;
  gap: ${space['spacing-sm']};
  overflow-x: auto;
  white-space: normal;

  h3 {
    display: flex;
    align-items: flex-end;
  }

  h3 > a, h3 > a:visited {
    color: ${colors['neutral-text-default']};
  }

  @media (${media.desktop}) {
    font-size: ${fontSizes['font-size-body-1']};
  }
`
