import { home } from 'data/seo'
import { Home as HomeContainer } from 'views/home'

export default function Home() {
  return <HomeContainer />
}

export async function getStaticProps() {
  return {
    props: { SEO: home }
  }
}
