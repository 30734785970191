import styled from 'styled-components'

import { theme } from 'styles/theme'

const { fontSizes, media, space, zIndices } = theme

export const Hero = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`

export const TextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-top: ${space['spacing-xl']};
  padding-bottom: ${space['spacing-xxl']};
  gap: ${space['spacing-sm']};

  > h1 {
    margin-bottom: ${space['spacing-md']};
  }

  > button {
    margin-top: ${space['spacing-md']};
    gap: ${space['spacing-xxs']};
  }

  > h2 {
    width: 18rem;
  }

  @media (${media.tablet}) {
    > h2 {
      width: 36rem;
    }

    > p {
      width: 29rem;
    }
  }

  @media (${media.desktop}) {
    padding: 7.5rem 8.5rem 6.5rem;

    > h1 {
      font-size: ${fontSizes['font-size-body-1']};
    }

    > h2 {
      font-size: ${fontSizes['font-size-heading-2']};

      > span {
        font-size: ${fontSizes['font-size-heading-2']};
      }
    }
  }
`

const BasePolygons = styled.div`
  position: absolute;
  left: 55%;
  bottom: -${space['spacing-md']};

  .blue-polygon {
    position: relative;
  }

  .pink-polygon {
    position: absolute;
    left: ${space['spacing-xxl']};
  }
`

export const Polygons = styled(BasePolygons)`
  @media (${media.tablet}) {
    left: 50%;
    bottom: -${space['spacing-xl']};
  }

  @media (${media.desktop}) {
    display: none;
  }
`

export const DesktopPolygons = styled(BasePolygons)`
  display: none;

  @media (${media.desktop}) {
    position: absolute;
    left: 50%;
    top: 25.5rem;
    z-index: ${zIndices[1]};
    display: block;

    .blue-polygon {
      width: 21.25rem;
      height: 18.875rem;
    }

    .pink-polygon {
      width: 21.25rem;
      height: 18.875rem;
    }
  }
`

export const ImageWrapper = styled.div`
  position: absolute;
  inset: 0 0 auto auto;
  width: 65%;
  overflow: hidden;

  @media (${media.tablet}) {
    top: -15%;
    width: 70%;
  }

  @media (${media.desktop}) {
    right: 0;
    width: 61.938rem;
  }
`
