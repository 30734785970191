import { memo } from 'react'

import { Icon } from '@cash-me/react-components'
import NextImage from 'next/image'

import { pushDataLayer } from 'utils/push-data-layer'

import { ProductCardProps } from './product-card.model'
import * as S from './styles'

const ProductCardComponent = ({ image, priority = false, link, dataLayer, children }: ProductCardProps) => (
  <S.Card>
    <S.Image>
      <NextImage
        src={image.src}
        alt={image.alt}
        width={image.width}
        height={image.height}
        priority={priority}
        sizes="100vw"
        style={{
          maxWidth: '100%',
          maxHeight: '117px'
        }}
      />
    </S.Image>
    <S.CardInfo>{children}</S.CardInfo>
    <S.Link {...(dataLayer && { onClick: () => pushDataLayer(dataLayer) })} href={link}>
      Saiba mais
      <Icon name="arrowRight" color="var(--colors-blue-icon-strong)" size={24} />
    </S.Link>
  </S.Card>
)

export const ProductCard = memo(ProductCardComponent)
