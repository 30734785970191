import { forwardRef, memo } from 'react'

import { PolygonProps } from './polygon.model'
import { Wrapper } from './styles'
import * as SvgList from './svg'

export const Polygon = memo(
  forwardRef<SVGSVGElement, PolygonProps>(({ type, className = '', ...rest }, ref) => {
    const Component = SvgList[type] as JSX.Element

    if (!!type) {
      return <Wrapper as={Component} ref={ref} className={className} {...rest} />
    }

    return null
  })
)

Polygon.displayName = 'Polygon'
