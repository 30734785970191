import styled from 'styled-components'

interface WrapperProps {
  readonly as?: JSX.Element
  readonly type?: string
  readonly color?: string | 'blue' | 'pink'
  readonly width?: number
  readonly height?: number
  readonly draw?: 'fill' | 'stroke'
  readonly className?: string
  readonly strokeWidth?: string
}

export const Wrapper = styled.svg<WrapperProps>`
  width: ${({ width }) => width && `${width}px`};
  height: ${({ height }) => height && `${height}px`};
`
