import styled from 'styled-components'

import { theme } from 'styles/theme'
const { borderWidths, colors, fonts, fontSizes, fontWeights, lineHeights, media, radii, space, transitions } = theme

export const RDStationFormsWrapper = styled.div`
  form {
    label,
    input,
    span,
    button,
    div {
      font-family: ${fonts['font-family-base']} !important;
    }
  }

  .bricks--row.rd-row:not(:last-child) {
    display: none !important;
  }

  .bricks--column.rd-column > div {
    padding: 0 !important;

    > div.rd-form {
      margin-top: 0;
    }
  }

  /* Add new form html ids here or it won't overwrite */
  #conversion-form-condo-2023-mai-formulario-lp-energia-solar,
  #conversion-form-cashmember,
  #conversion-form-cashme-condo-hotsite {
    label.bricks-form__label {
      left: 0 !important;
      font-size: ${fontSizes['font-size-overline']};
      font-weight: ${fontWeights['font-weight-medium']};
      text-transform: uppercase;
      transform-origin: left top;
      cursor: text;
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, top 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

      @media (${media.desktop}) {
        left: ${space['spacing-xs']};
        margin: 0;
      }
    }

    input.bricks-form__input,
    select.bricks-form__input,
    div.select2-container {
      padding: 0;
      width: 100%;
      background: transparent;
      border: none;
      outline: none;
      color: ${colors['neutral-text-default']};
      font-weight: ${fontWeights['font-weight-regular']};
      font-size: ${fontSizes['font-size-body-1']};

      &::placeholder {
        color: transparent;
      }
    }

    button.rd-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: ${space['spacing-mega']};
      min-height: ${space['spacing-mega']};
      gap: ${space['spacing-lg']};
      padding: 0 ${space['spacing-sm']};
      border: ${borderWidths['border-stroke-none']};
      border-radius: ${radii['corner-radius-sm']};
      background-color: ${colors['blue-background-strong-default']};
      color: ${colors['blue-text-strong']};
      font-family: ${fonts['font-family-base']};
      font-size: ${fontSizes['font-size-button']};
      font-weight: ${fontWeights['font-weight-medium']};
      line-height: ${lineHeights['font-line-height-lg']};
      text-transform: capitalize;
      transition: ${transitions['default']};
      cursor: pointer;
    }

    .bricks-form__field {
      position: relative;
      flex: 1;
      height: ${space['spacing-mega']};
      padding: ${space['spacing-xs']} ${space['spacing-xs']} ${space['spacing-xxs']} 0;
      border-bottom: ${borderWidths['border-stroke-micro']} solid ${colors['neutral-border-default']};
      transition: all 0.3s ease;

      a {
        background: transparent;
      }

      &:hover {
        border-bottom-color: ${colors['neutral-border-hover']};
        box-shadow: inset 0 -${borderWidths['border-stroke-micro']} 0 0 ${colors['neutral-border-hover']};
      }

      &:focus-within {
        border-bottom-color: ${colors['blue-border-hover']};
        box-shadow: inset 0 -${borderWidths['border-stroke-micro']} 0 0 ${colors['blue-border-hover']};

        label {
          color: ${colors['blue-text-default']} !important;
        }
      }

      /* Radio buttons */
      .bricks-form__field__option {
        margin-top: ${space['spacing-xxs']};

        label.bricks-form__label {
          cursor: pointer;
          display: flex;
          gap: ${space['spacing-xxs']};
        }

        input {
          display: grid;
          place-content: center;
          appearance: none;
          color: currentcolor;
          width: ${space['spacing-xs']};
          height: ${space['spacing-xs']};
          background-color: ${colors['neutral-background-default']};
          border: ${borderWidths['border-stroke-micro']} solid ${colors['neutral-border-default']};
          border-radius: ${radii['corner-radius-circular']};
          cursor: pointer;
          transition: all 0.4s ease;

          &:hover {
            border-color: ${colors['blue-border-hover']};
          }

          &::before {
            content: '';
            width: ${space['spacing-xs']};
            height: ${space['spacing-xs']};
            border-radius: ${radii['corner-radius-circular']};
            opacity: 0;
            box-shadow: inset ${space['spacing-sm']} ${space['spacing-sm']} #fff;
            transition: opacity 0.4s ease;
          }

          &:checked::before {
            opacity: 1;
            border: ${space['spacing-xxxs']} solid ${colors['success-border-default']};
          }
        }
      }

      @media (${media.desktop}) {
        margin-bottom: ${space['spacing-md']};
        padding-left: ${space['spacing-xs']};
        border: ${borderWidths['border-stroke-micro']} solid ${colors['neutral-border-default']};
        border-radius: ${radii['corner-radius-sm']};

        &:hover {
          border-color: ${colors['neutral-border-hover']};
          box-shadow: inset 0 0 0 ${borderWidths['border-stroke-micro']} ${colors['neutral-border-hover']};
        }

        &:focus-within {
          border-color: ${colors['blue-border-hover']};
          box-shadow: inset 0 0 0 ${borderWidths['border-stroke-micro']} ${colors['blue-border-hover']};
        }
      }
    }
  }
`
