import { FC, memo } from 'react'

import { Text, Button } from '@cash-me/react-components'

import { CtaBarProps } from './cta-bar.model'
import * as S from './styles'

export const CtaBar: FC<CtaBarProps> = memo(({ title = '', buttonLabel, onClick, hideTablet = false }) => (
  <S.CTA hideTablet={hideTablet}>
    {title && (
      <Text size="heading5" weight="medium">
        {title}
      </Text>
    )}
    <Button size="md" variants="text-only" onClick={onClick}>
      {buttonLabel}
    </Button>
  </S.CTA>
))

CtaBar.displayName = 'CtaBar'
